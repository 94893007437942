<template>
  <div class="refund">
    <a-spin :spinning="spinning">
      <!-- <react-table
          ref="listTable"
          :table-data="tableData"
          :total-items="totalItems"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :columns="columns"
          :hide-row-selection="true"
          :is-full="true"
          :current-page="pageNumber"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >  -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row align="middle" :gutter="8">
          <!-- <a-col class="gutter-row" :span="2" style="text-align: right;">姓名：</a-col> -->
          <!-- <a-col :span="4">
              <a-input
                :placeholder="l('请输入姓名')"
                v-model.trim="realName"
              />
            </a-col> -->
          <a-col :span="5">
            <a-input
              :placeholder="l('姓名/手机号/身份证号/银行卡号')"
              v-model.trim="filterText"
            />
          </a-col>
          <!-- <a-col class="gutter-row" :span="2" style="text-align: right;">选择客户：</a-col> -->
          <a-col :span="4">
            <a-select
              style="width: 100%"
              placeholder="请选择客户"
              v-model="customerId"
              allowClear
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in cusOptions"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-input
              :placeholder="l('请输入发放批次号')"
              v-model.trim="batchNo"
            />
          </a-col>
          <a-col class="gutter-row" :span="7">
            <a-range-picker
              v-model="batchTime"
              :show-time="{ format: 'HH:mm' }"
              :placeholder="['开始时间', '结束时间']"
              valueFormat="YYYY-MM-DD HH:mm"
              style="width: 100%"
              @change="onChange"
              @ok="onOk"
            />
          </a-col>
          <a-col :span="3">
            <a-button
              @click="search"
              type="primary"
              v-if="isGranted('Pages.FinancialRejectPermissions.Query')"
            >
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearFilterAndRefresh">
              {{ l("Reset") }}</a-button
            >
          </a-col>

          <a-col :span="2" style="text-align: right">
            <a-button
              @click="importData()"
              v-if="isGranted('Pages.FinancialRejectPermissions.Import')"
            >
              <a-icon type="file-excel" />
              <span>{{ l("导入") }}</span>
            </a-button>
          </a-col>
        </a-row>
      </ele-table>
    </a-spin>

    <a-modal
      centered
      :title="addInvoiceModal.title"
      :visible="addInvoiceModal.visible"
      :confirm-loading="addInvoiceModal.confirmLoading"
      :maskClosable="addInvoiceModal.maskClosable"
      :destroyOnClose="addInvoiceModal.destroyOnClose"
      :width="addInvoiceModal.width"
      @ok="addInvoiceModal.confirm"
      @cancel="addInvoiceModal.cancel"
      :cancelText="addInvoiceModal.cancelText"
      :okText="addInvoiceModal.okText"
    >
      <div style="text-align: center">
        {{ addInvoiceModal.content }}
      </div>
    </a-modal>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import ImportForm from "./importform";

export default {
  mixins: [AppComponentBase],
  components: {
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      // 表格
      columns: [
        {
          title: this.l("退票流水号"),
          dataIndex: "serialNumber",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "serialNumber" },
        },
        {
          title: this.l("发放批次号"),
          dataIndex: "batchNo",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "batchNo" },
        },
        {
          title: this.l("发放明细号"),
          dataIndex: "payRecordNo",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "payRecordNo" },
        },
        {
          title: this.l("客户名称"),
          dataIndex: "customerName",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("姓名"),
          dataIndex: "userName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: this.l("银行卡号"),
          dataIndex: "bankNum",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "bankNum" },
        },
        {
          title: this.l("银行名称"),
          dataIndex: "bankName",
          sorter: false,
          width: 160,
          align: "center",
          scopedSlots: { customRender: "bankName" },
        },

        {
          title: this.l("金额"),
          dataIndex: "amount",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "idCard",
          width: 200,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("发放时间"),
          dataIndex: "payTime",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "payTime" },
        },
        {
          title: this.l("失败提示"),
          dataIndex: "reason",
          sorter: false,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "reason" },
        },
        // {
        //   title: this.l("Actions"),
        //   dataIndex: "actions",
        //   fixed: 'right',
        //   sorter: false,
        //   align: "center",
        //   width: '100',
        //   scopedSlots: { customRender: "actions" }
        // },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // list-table btns显示参数
      actionsType: {},
      customerId: undefined,
      batchNo: "",
      batchTime: "",
      startTime: "",
      endTime: "",
      realName: "",
      cusOptions: [],
      addInvoiceModal: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 500,
        title: "申请开票",
        url: "",
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.addInvoiceModal.confirm = () => {};
          this.addInvoiceModal.visible = false;
        },
      },
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this._getCustomerOption();
  },
  mounted() {},
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onChange(value, dateString) {
      this.startTime = "";
      this.endTime = "";
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    },
    onOk(value) {
      console.log("onOk: ", value);
      this.startTime = value[0];
      this.endTime = value[1];
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    async getData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Salary/GetSalaryRefundRecordPaged",
          params: {
            customerId: this.customerId,
            batchNo: this.batchNo,
            startTime: this.startTime,
            endTime: this.endTime,
            realName: this.realName,
            filterText: this.filterText,
            sorting: this.request.sorting,
            maxResultCount: this.request.maxResultCount,
            skipCount: this.request.skipCount,
          },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            // standTypeStr: item.standType === 'Normal' ? '正常开票' : '独立开票'
            billTypeStr: item.billType === 0 ? "非垫付帐单" : "垫付帐单",
            payTime: moment(item.payTime).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getCustomerOption() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/Customer/GetCustomerOption",
          params: null,
        });
        this.cusOptions = res;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },

    selectChange(val) {
      console.log(val);
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.clientId = "";
      this.realName = "";
      this.batchNo = "";
      this.batchTime = "";
      this.startTime = "";
      this.endTime = "";
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            // { granted: this.isGranted('Pages.BillPermissions.Update'), name: this.l("修改"), icon: "edit", fn: (data) => {
            //   _this.createOrEdit(data, 'edit')
            // } },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    importData() {
      ModalHelper.create(
        ImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.getData();
      });
    },
    createClaimantBill(item, type) {
      ModalHelper.create(
        ClaimantBill,
        {
          params: {
            type: type, // edit,preview,create
            organizationUnitId: item ? item.organizationUnitId : null,
            id: item ? item.id : null,
          },
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.refund {
  min-height: 600px;
}
</style>