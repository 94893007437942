<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("导入") }}</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col class="gutter-row" :span="12" style="color:red;font-size:20px;">
        温馨提示：先下载模板再导入退款名单
      </a-col>
      <a-col class="gutter-row" :span="12" style="text-align: right">
        <a-button  @click="downLoad()">
          <a-icon type="download" />
          {{ l("下载模板") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-upload-dragger
            ref = "uploadExcel"
          name="file"
          :multiple="false"
          :action="importFromExcelUrl"
          accept=".xls,.xlsx"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">将文件拖到此处或点击上传</p>
          <p class="ant-upload-hint">只支持.xls,.xlsx文件格式上传。</p>
        </a-upload-dragger>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [ModalComponentBase],
  name: "import-form",
  data() {
    return {
      spinning: false,
      fileList: [],
      _fileDownloadService: "",
      importFromExcelUrl:
        AppConsts.remoteServiceBaseUrl + "/api/services/app/Salary/ImportSalaryRefundRecords",
      uploadHeaders: {},
    };
  },
  components: {},
  beforeCreate() {},
  created() {
    this._fileDownloadService = fileDownloadService;
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
  },
  mounted() {},
  methods: {
    handleChange(info) {
      console.log('info:', info);
      //   var isSuccess =  this.beforeUpload(info.file);
      //   if(!isSuccess){
      //       return false;
      //   }
      const status = info.file.status;
      if (status !== "uploading") {
        console.log('status !== "uploading"', info.file, info.fileList);
      }
      if (status === "done") {
        // let message = info.file.response.result.message;
        // console.log('status === "done"', message)
        // this.$message.success(`${info.file.name}导入成功`);
        this.fileList = info.file.response && info.file.response.data;
        if(info.file.response.result && info.file.response.result.fileName.indexOf('异常数据') !== -1) {
          this.$message.success(`${info.file.name}导入失败`);
          fileDownloadService.downloadTempFile(info.file.response.result);
        } else {
          this.$message.success(`${info.file.name}导入成功`);
        }
        // console.log(this.dataList);
      } else if (status === "error") {
        let message = info.file.response.error.message;
        this.$message.error(`${info.file.name}导入失败`);
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      console.log('file:', file);
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "xlsx" && fileName != "xls");

      if (!isFile) {
        this.$message.error("文件格式必须是xls或xlsx!");
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
      }
      return isFile && isLt10M;
    },
    //点击下载模板
    downLoad() {
      //   window.location.href = '/门店导入模板.xls'
      this._fileDownloadService.downloadTemplateFile("发放退票信息导入模板.xls");
    },
    testUpload(){

    }
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
